import React from 'react';
import Form from './Form';
import Footer from '../footer/Footer';



export default function Contact() {
  return (
    <div className='contactPage'>
      <h1 className='formHeader'>message me</h1>
      <Form />
    </div>
  )
}